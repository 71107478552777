// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-gallery-projects-gallery-js": () => import("./../../../src/components/Gallery/ProjectsGallery.js" /* webpackChunkName: "component---src-components-gallery-projects-gallery-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abilities-js": () => import("./../../../src/pages/abilities.js" /* webpackChunkName: "component---src-pages-abilities-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

