module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Micron | Precision Mold","short_name":"Micron","description":"شرکت طراحی و ساخت قالب های میکرون با هدف ارائه خدمت در زمینه های مهندسی محصول، طراحی و ساخت قالب های تزریق پلاستیک، بازرسی و رفع عیب قالب های تزریق پلاستیک و تولید قطعات پلیمری تا وزن ۱۲۰۰ گرم، آماده رفع تمامی نیازهای مشتریان می باشد.","start_url":"/","background_color":"#e42229","theme_color":"#e42229","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b550a7df88b4c66404bbc515b039b916"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","localeJsonNodeName":"locales","languages":["fa","en"],"defaultLanguage":"fa","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
